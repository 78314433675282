import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import AgenciesMarquee from "../components/agencies-marquee"
import ProductHero from "../components/product-hero"

class StaticPage extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {
    return (
      <Layout>
        <SEO title={this.props.pageContext.title} />
        <ProductHero html={this.props.pageContext.heroText} />
        <div className="container static-page-container px-4">
          <h2 className="is-size-4">{this.props.pageContext.title}</h2>
          <div
            className="wp-text"
            dangerouslySetInnerHTML={{
              __html: this.props.pageContext.content,
            }}
          ></div>
        </div>
        <AgenciesMarquee />
      </Layout>
    )
  }
}

export default StaticPage
